import React from "react";

function TasteText() {
  return (
    <pre>
      <span className="code-grey">{"<"}</span>
      <span className="code-blue">img</span>
      <span className="code-lightblue"> src</span>
      <span className="code-grey">=</span>
      <span className="code-purple">{"{"}</span>
      <span className="code-blue">Taste</span>
      <span className="code-purple">{"}"}</span>
      <span className="code-lightblue"> alt</span>
      <span className="code-grey">=</span>
      <span className="code-orange">"Taste"</span>
      <span className="code-grey">{" />"}</span>
    </pre>
  );
}

export default TasteText;
