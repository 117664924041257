import React from "react";
import HeroSection from "../HeroSection/HeroSection";
import CaseSection from "../CaseSection/CaseSection";
import Footer from "../Footer/Footer";
import "./HomePage.css";
import AboutmePage from "../AboutmePage/AboutmePage";

function HomePage({ isLogicVisible, toggleSwitch }) {

  return (
    <div
      className={`page-container ${
        isLogicVisible ? "code-view" : "design-view"
      }`}
    >
      <HeroSection
        isLogicVisible={isLogicVisible}
        toggleSwitch={toggleSwitch}
      />
      <CaseSection isLogicVisible={isLogicVisible} />
      <AboutmePage isLogicVisible={isLogicVisible}/>
    <Footer isLogicVisible={isLogicVisible}/>
    </div>
  );
}

export default HomePage;
