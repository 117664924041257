import React from "react";
import "./CaseSection.css";
import Card from "./Card";
import { cardsData } from "./cardsData";

function CaseSection({ isLogicVisible }) {
    return (
        <div className={`container ${isLogicVisible ? "code-view" : "design-view"}`}>
            <div className="inner-container">
                <p className="section-title">Product design</p>
                <div className="cards-container">
                {cardsData.productDesign.map((card, index) => (
                        <Card
                            key={card.title}
                            title={card.title}
                            readTitle={card.readTitle}
                            readText={card.readText}
                            background={card.background}
                            redirectPath={card.redirectPath}
                            isInProgress={index === 2} 
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default CaseSection;