import React, { useState } from "react";
import "./HeroSection.css";
import TasteImage from "../../assets/taste.svg";
import LogicImage from "../../assets/logic.svg";
import HeaderText from "./CodeTexts/HeaderText";
import BodyText from "./CodeTexts/BodyText";
import TasteText from "./CodeTexts/TasteText";
import LogicText from "./CodeTexts/LogicText";

function HeroSection({ isLogicVisible, toggleSwitch }) {

    const [productToggle, setProductToggle] = useState("Product");
    const [designerToggle, setDesignerToggle] = useState("Designer");

    const productOptions = ["Product", "UX", "UI", "Multimedia", "Game"];
    const designerOptions = ["Designer", "Engineer", "Developer", "Creator"];

    const handleProductToggle = () => {
        const currentIndex = productOptions.indexOf(productToggle);
        const nextIndex = (currentIndex + 1) % productOptions.length;
        setProductToggle(productOptions[nextIndex]);
    };

    const handleDesignerToggle = () => {
        const currentIndex = designerOptions.indexOf(designerToggle);
        const nextIndex = (currentIndex + 1) % designerOptions.length;
        setDesignerToggle(designerOptions[nextIndex]);
    };

    const handleSwitchClick = () => {
        console.log("Switch button clicked");
        toggleSwitch();
    };

    return (
        <div className={`hero-section ${isLogicVisible ? "code-view" : "design-view"}`}>
            <div className="hero-content">
                <div className="top-content">
                    {!isLogicVisible && (
                        <div className="toggle-container">
                            <span className="location-text">I'm Oscar, a</span>
                            <div className="rolebuttons">
                            <button className="toggle-button" onClick={handleProductToggle}>{productToggle}</button>
                            <button className="toggle-button" onClick={handleDesignerToggle}>{designerToggle}</button>
                            </div>
                            <span className="location-text">from Barcelona</span>
                        </div>
                    )}
                    <p className="hero-title" key={isLogicVisible ? "title-code" : "title-design"}>
                        {isLogicVisible ? (
                            <HeaderText key="header-text" />
                        ) : (
                            "bridging the gap between"
                        )}
                    </p>
                </div>
                <div className="images-container">
                    <div
                        className={`text-container ${isLogicVisible ? "code-view" : "design-view"}`}
                        key={isLogicVisible ? "taste-text-code" : "taste-image"}
                    >
                        {isLogicVisible ? (
                            <TasteText key="taste-text" />
                        ) : (
                            <img src={TasteImage} alt="Taste" key="taste-image" className="resized-image" />
                        )}
                    </div>
                    <div className="switch-button" onClick={handleSwitchClick}>
                        <div className={`button ${isLogicVisible ? "switched" : ""}`} />
                    </div>
                    <div
                        className={`text-container ${isLogicVisible ? "code-view" : "design-view"}`}
                        key={isLogicVisible ? "logic-text-code" : "logic-image"}
                    >
                        {isLogicVisible ? (
                            <LogicText key="logic-text" />
                        ) : (
                            <img src={LogicImage} alt="Logic" key="logic-image" className="resized-image" />
                        )}
                    </div>
                </div>
                <div className="bottom-content">
                    {isLogicVisible ? (
                        <p className="hero-subtitle"><BodyText key="body-text" /></p>
                    ) : (
                        <div className="button-container">

                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default HeroSection;