import React from "react";
import { useNavigate } from "react-router-dom";
import "./CaseSection.css";
import { ArrowRight, HourglassMedium } from "@phosphor-icons/react";


function Card({ title, readTitle, readText, background, redirectPath, isInProgress }) {
  const navigate = useNavigate();

  const handleCardClick = () => {
    if (!isInProgress) {
      navigate(redirectPath);
    }
  };

  const buttonClass = `card-button ${isInProgress ? 'in-progress' : ''}`;

  return (
    <div className="card">
      <div className="content first">
        <div className="text-div">
          <p className="card-title">{title}</p>
          <p className="card-pretitle">{readTitle}</p>
          <p className="card-text">{readText}</p>
        </div>
        <div className="img-div">
        <button className={buttonClass} onClick={handleCardClick} disabled={isInProgress}>
            <span>{isInProgress ? "Work in progress" : "Read case"}</span>
            {isInProgress ? <HourglassMedium size={20} weight="bold" className="footer-icon" /> : <ArrowRight size={20} weight="bold" className="footer-icon" />}
          </button>
        </div>
      </div>
      <span className="second">
        <img src={background} alt={title} className="card-image" />
      </span>
    </div>
  );
}

export default Card;
