import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../CaseStyles.css";

import Footer from "../../Footer/Footer";
import { ArrowLeft } from "@phosphor-icons/react";
import presentation from "../../../assets/installments/presentation.png";
import introsplits from "../../../assets/installments/introsplits.png";
import Spacing from "../../../components/Spacing";
import data1 from "../../../assets/installments/data1.png";
import data2 from "../../../assets/installments/data2.png";
import data3 from "../../../assets/installments/data3.png";
import data4 from "../../../assets/installments/data4.png";
import newsplits1 from "../../../assets/installments/newsplits1.png";
import newsplits2 from "../../../assets/installments/newsplits2.png";
import newsplits3 from "../../../assets/installments/newsplits3.png";
import old1 from "../../../assets/installments/old1.png";
import old2 from "../../../assets/installments/old2.png";
import old3 from "../../../assets/installments/old3.png";
import cj1 from "../../../assets/installments/cj1.png";
import cj2 from "../../../assets/installments/cj2.png";
import newauth from "../../../assets/installments/newauth.png";
import newpost from "../../../assets/installments/newpostsplit.png";
import newauto from "../../../assets/installments/newautosplit.png";
import Card from "../../CaseSection/Card";
import { cardsData } from "../../CaseSection/cardsData";

function InstallmentsPage({ isLogicVisible }) {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const redirectCard = cardsData.productDesign.find(card => card.redirectPath === '/automaticparking');

  return (
    <div
      className={`case-section ${isLogicVisible ? "code-view" : "design-view"}`}
    >
      
      <div className="breadcrumb">
        <Link to="/">
        <ArrowLeft size={24} weight="bold" />
        
          <p className="casehomelink">Back</p>
        </Link>
      </div>
      <div className="case-content">
        <p className="titlecase">
        Achieved 100% increase in revenues due to installment payment functionality
        </p>
        <Spacing className="spacing-lg" />
        <div className="divDet">
          <div className="containerDet">
            <p className="detLabel">Role</p>
            <p className="detText">Product Designer</p>
          </div>
          <div className="containerDet">
            <p className="detLabel">Company</p>
            <p className="detText">Bank - {"(Name is confidential)"}</p>
          </div>
          <div className="containerDet">
            <p className="detLabel">Project timeline</p>
            <p className="detText">Sep 2023 - Feb 2024</p>
          </div>
        </div>
        <Spacing className="spacing-xl" />
        <div className="imgcase">
          <img className="imgsm" src={presentation} alt="placeholder" loading="lazy" />
        </div>
        <Spacing className="spacing-xl" />
        <p className="pcase">
          At the end of 2023, the bank's app installment payments feature had a
          conversion ratio of 30%. This led the bank to carry out a study with
          data and business analysts to define a series of initiatives. The
          objective was to double the profit from installment operations within
          a year.
          <br />
          <br />I was part of an ambitious 5-month project to redesign the
          installment payments experience.
        </p>
        <Spacing className="spacing-lg" />
        <div className="infonda">
          <p className="pinfo">
            To comply with my non-disclosure agreement, I have omitted and
            obfuscated confidential information in this case study. All
            information in this case study is my own and does not necessarily
            reflect the views of the bank.
          </p>
        </div>
        <Spacing className="spacing-lg" />
        <p className="subtitlecase">Introduction</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          Installment payments help users manage their finances by dividing
          payments into manageable monthly installments. Users can select the
          number of months to spread the payment over, making it easier to
          budget and manage expenses. However, users often distrust these
          features due to the interest charged, leading to hesitation.
        </p>
        <Spacing className="spacing-xl" />
        <div className="imgcase">
          <img className="imgsm" src={introsplits} alt="placeholder"  loading="lazy"/>
        </div>
        <Spacing className="spacing-xl" />
        <p className="subtitlecase">
          The Challenge: Empowering Users in 7 Months
        </p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          Our goal was to offer the best user experience while doubling the
          profit from installment operations within a year. The challenge was
          significant: only 3 out of 10 users completed the installment payments
          process. We aimed to increase this percentage, create a scalable
          functionality, and improve the overall user experience.
          <br />
          <br />
          Our team's vision was clear:
          <br />
          <br />
          <ul>
            <li>Make the experience easy and intuitive.</li>
            <li>
              Empower users to control their finances while maintaining
              excellent customer service.
            </li>
            <li>
              Balance data-driven decisions with qualitative user research.
            </li>
            <li>
              Continuously iterate and measure performance through A/B testing.
            </li>
            <li>
              Maintain a holistic view of the user journey, from acquisition to
              experience design.
            </li>
          </ul>
        </p>
        <Spacing className="spacing-md" />
        <p className="subtitlecase">My Role</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          I worked as the product designer from September 2023 to February 2024
          in a Business Unit that included a strategy analyst, two data
          analysts, a marketing specialist, and a product owner.
        </p>
        <Spacing className="spacing-md" />
        <p className="subtitlecase">Kickoff: Gathering Insights</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          At the project's start, we had a wealth of information:
          <br />
          <br />
          <ul>
            <li>Quantitative data from Data Analytics.</li>
            <li>
              Qualitative insights from thousands of customer service calls.
            </li>
            <li>
              Previous interviews with users about their motivations for
              splitting payments.
            </li>
            <li>Initiatives identified by the strategy department.</li>
            <li>Competitive benchmarks.</li>
          </ul>
        </p>
        <Spacing className="spacing-md" />
        <p className="subtitlecase">Early Insights from Data</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          I analyzed the data to guide our decisions. Here are the key findings:
        </p>
        <Spacing className="spacing-xl" />
        <div className="divrow">
          <div className="divdata">
            <div className="imgcase">
              <img className="imgxs" src={data1} alt="placeholder" loading="lazy" />
            </div>
            <Spacing className="spacing-xs" />
            <p className="captioncase">
              The funnel conversion rate was 30%, with the biggest drop{" "}
              {"(45%)"}
              occurring at the split selection stage.
            </p>
          </div>
          <div className="divdata">
            <div className="imgcase">
              <img className="imgxxxxs" src={data2} alt="placeholder" loading="lazy" />
            </div>
            <Spacing className="spacing-xs" />
            <p className="captioncase">
              Users found the splits selection screen confusing and unintuitive.
            </p>
          </div>
        </div>
        <Spacing className="spacing-xl" />
        <div className="divrow">
          <div className="divdata">
            <div className="imgcase">
              <img className="imgxxxs" src={data3} alt="placeholder" loading="lazy" />
            </div>
            <Spacing className="spacing-xs" />
            <p className="captioncase">
              Out of 15 available splits, the most commonly used were 2, 3, 6,
              and 12 months.
            </p>
          </div>
          <div className="divdata">
            <div className="imgcase">
              <img className="imgxxs" src={data4} alt="placeholder" loading="lazy" />
            </div>
            <Spacing className="spacing-xs" />
            <p className="captioncase">
              Users mainly used BNPL {"(Buy Now Pay Later)"} options and the
              bank's app for unforeseen charges.
            </p>
          </div>
        </div>
        <Spacing className="spacing-xl" />
        <p className="subtitlecase">
          Discovery: More Control Before, During, and After Splitting
        </p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          Users wanted full control over their finances, especially regarding
          decision-making. They emphasized the need for transparency about
          interest rates before confirming any splits. They desired a quick,
          simple, and transparent process.
        </p>
        <Spacing className="spacing-md" />
        <p className="subtitlecase">Reframing the Problem</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          The existing installment payments operation lacked efficiency and
          transparency. It failed to impact users during the purchase process,
          had poor interaction design, and provided little pre-decision
          information.
          <br />
          <br />
          Our central question became: How can we give users control to manage
          their finances in a usable and transparent way?
          <br />
          <br />
          We proposed Installment Payments 2.0, a redesign to cover the entire
          user journey and offer the best experience before, during, and after
          dividing payments.
        </p>
        <Spacing className="spacing-md" />
        <p className="subtitlecase">Introducing Installment Payments 2.0</p>
        <Spacing className="spacing-md" />
        <p className="pcase">
          In an age where you can buy and receive something the same day,
          Installment Payments 2.0 lets you control expenses and purchase
          products now while deferring payment over your chosen number of
          installments.
        </p>
        <Spacing className="spacing-md" />
        <p className="hcase">Post-purchase split</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          Users see the most popular splits with clear information on the total
          amount, including interest, for transparency and informed
          decision-making.
        </p>
        <Spacing className="spacing-xl" />
        <div className="imgcase">
          <img className="imglg" src={newsplits1} alt="placeholder" loading="lazy" />
        </div>
        <Spacing className="spacing-xl" />
        <p className="hcase">Authorize and split feature</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          Users can now split purchases directly when authorizing payments in
          the bank app, making the process easy and intuitive.
        </p>
        <Spacing className="spacing-xl" />
        <div className="imgcase">
          <img className="imgsm" src={newsplits2} alt="placeholder" loading="lazy" />
        </div>
        <Spacing className="spacing-xl" />
        <p className="hcase">Automatic purchase split</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          Users can set a threshold amount above which all payments will be
          automatically divided into your chosen splits, with options to receive
          notifications for accepting, modifying, or canceling upcoming splits.
        </p>
        <Spacing className="spacing-xl" />
        <div className="imgcase">
          <img className="imgsm" src={newsplits3} alt="placeholder" loading="lazy" />
        </div>
        <Spacing className="spacing-xl" />
        <p className="subtitlecase">
          State of the Installment Payments Operation
        </p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          The bank's payment splitting feature previous to the redesign
          included:
        </p>
        <Spacing className="spacing-md" />
        <p className="hcase">Split your payments already made</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          Split payments for past transactions by choosing the number of months.
        </p>
        <Spacing className="spacing-xl" />
        <div className="imgcase">
          <img className="imgmd" src={old1} alt="placeholder" loading="lazy" />
        </div>
        <Spacing className="spacing-xl" />
        <p className="hcase">Split your next payment</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          Set a threshold amount above which your next payment will be
          automatically divided into your chosen splits.
        </p>
        <Spacing className="spacing-xl" />
        <div className="imgcase">
          <img className="imgxxxs" src={old2} alt="placeholder" loading="lazy" />
        </div>
        <Spacing className="spacing-xl" />
        <p className="hcase">Split all your next payments</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          Set a threshold amount above which all payments will be automatically
          divided into your chosen splits.
        </p>
        <Spacing className="spacing-xl" />
        <div className="imgcase">
          <img className="imgxxxs" src={old3} alt="placeholder" loading="lazy" />
        </div>
        <Spacing className="spacing-xl" />
        <p className="subtitlecase">How We Got There</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          Three primary questions informed my design strategy:
          <br />
          <br />
          <ul>
            <li>How to offer installment payments at all times?</li>
            <li>What is the best way to split payments?</li>
            <li>How to design for everyone?</li>
          </ul>
          <br />
          Understanding that the feature would be used by millions of people
          aged 18 and up, the process needed to be simple, understandable, and
          free of cognitive overload.
          <br />
          <br />
          Transparency was crucial since users previously abandoned the funnel
          due to unclear interest rates.
          <br />
          <br />I defined a customer journey map to identify interaction points
          and opportunities to offer installment payments, aiming to compete
          with BNPLs at key moments.
        </p>
        <Spacing className="spacing-xl" />
        <div className="imgcase">
          <img className="imgmd" src={cj1} alt="placeholder" loading="lazy" />
        </div>
        <Spacing className="spacing-xl" />
        <p className="subtitlecase">Competing with BNPLs</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          BNPLs offered splitting on the establishment's payment confirmation
          screen, a step the bank couldn't directly impact. The bank as such
          could not make an impact at that time. 
          <br />
          <br />
          I focused on discovering
          that other moments before and after the purchase the bank appeared to
          offer the fractionation.
        </p>
        <Spacing className="spacing-md" />
        <p className="hcase">Identifying the moment to compete against BNPLs</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          In a session with the team I asked the Product Owner and the Marketing
          Specialist what moments of interaction there are with the user when
          making a purchase in an establishment. 
          <br />
          <br />
          If there is any type of
          campaign prior to entering the establishment that can detect the user
          via GPS or after making the purchase.
        </p>
        <Spacing className="spacing-md" />
        <p className="hcase">
          The problem: Inviting users through marketing campaigns
        </p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          We identified a post-purchase campaign that offered the user to split
          the purchase made. We thought about refining this notification in
          order to make the user split after the purchase.
          <br />
          <br />
          But we had the same
          problem: We were not competing against the BNPL's, we were just
          strategically using a push notification to invite the user to split.
          <br />
          <br />
          Also, looking at the data, this push notification had a very low
          conversion {"(2%)"}. We discarded this option.
        </p>
        <Spacing className="spacing-md" />
        <p className="hcase">Finding the moment</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          In this session, we identified another moment. There was a prior
          interaction between the user and the bank app before finalizing the
          purchase: payment authorization.
          <br />
          <br />
          This step consisted of the user
          receiving a notification on their mobile phone that required them to
          enter the app to authorize the payment.
          <br />
          <br />
          The strategy was to offer splitting at the payment authorization step.
          I had the moment to compete with the BNPL's.
        </p>
        <Spacing className="spacing-xl" />
        <div className="imgcase">
          <img className="imgmd" src={cj2} alt="placeholder" loading="lazy" />
        </div>
        <Spacing className="spacing-xl" />
        <p className="hcase">The problem: A/B Testing challenges</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          Due to technical and budget constraints, we could only perform one A/B
          test in production. We chose to test the new experience for splitting
          payments already made.
          <br />
          <br />
          To mitigate the impact of this limitation and
          also to evaluate the experience, we conducted unmoderated Think Out
          Loud tests using Ballpark.
          <br />
          <br />
          After iterations with the team, performed the think out loud test with
          users, accessibility and legal checks and the refinement session with
          the development team, we had a version ready for implementation.
        </p>
        <Spacing className="spacing-xl" />
        <div className="imgcase">
          <img className="imglg" src={newauth} alt="placeholder" loading="lazy" />
        </div>
        <Spacing className="spacing-xl" />
        <p className="subtitlecase">Redesigning the Splits Selector</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          User feedback highlighted discomfort with the previous component for
          choosing splits and a need for more information before deciding to
          split.
          <br />
          <br />
          I hypothesized that low conversion was due to poor usability
          and lack of interest-related information. After several iterations, I
          designed a new version ready for A/B testing.
        </p>
        <Spacing className="spacing-xl" />
        <div className="imgcase">
          <img className="imglg" src={newpost} alt="placeholder" loading="lazy" />
        </div>
        <Spacing className="spacing-xl" />
        <p className="subtitlecase">Defining How to Split Next Payments</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          Our initial redesign combined splitting next and future purchases,
          simplifying the process and addressing user desires for more control.
          <br />
          <br />
          Data showed users often tested splitting on the next purchase before
          applying it to all future payments, reinforcing our hypothesis.
        </p>
        <Spacing className="spacing-xl" />
        <div className="imgcase">
          <img className="imglg" src={newauto} alt="placeholder" loading="lazy" />
        </div>
        <Spacing className="spacing-xl" />
        <p className="subtitlecase">Positive Impact and Future Work</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          The redesign had a positive impact on most metrics three months
          post-launch. However, customer service calls remained higher than
          expected, likely due to the new operations.
          <br />
          <br />
          <ul>
            <li>
              KPI Achievement: Estimated 140% profit increase by year-end.
            </li>
            <li>
              Drop-off Rate on Split Selector screen: Reduced from 45% to 23%.
            </li>
            <li>
              Post-Purchase Conversion Rate on the full funnel: Increased by
              14%.
            </li>
            <li>Average Ticket: Increased by 5%.</li>
            <li>Post-Purchase Traffic: Increased by 11%.</li>
            <li>
              Conversion Rate for Authorization Splitting: Increased by 6%.
            </li>
          </ul>
          <br />
          The Automatic purchase split feature is set to launch in December
          2024, pending technical capacity. For confidentiality, actual values
          for these metrics are omitted.
        </p>
        <Spacing className="spacing-xl" />
        <div className="casedivider"></div>
        <Spacing className="spacing-xl" />
        <div className="nextcasesection">
        <p className="nextcasetitle">
          Next case study
        </p>
        
        <Card {...redirectCard} />
        </div>
      </div>
      <Footer isLogicVisible={isLogicVisible} />
    </div>
  );
}

export default InstallmentsPage;
