import React from "react";
import bcn from "../../assets/bcnimg.png";
import code from "../../assets/codeframe.png";
import pfp from "../../assets/pfp.png";
import "./AboutmePage.css";
import Spacing from "../Spacing";
import "../Casestudies/CaseStyles.css";

function AboutmePage({ isLogicVisible }) {
  return (
    <div
      className={`aboutme-section ${
        isLogicVisible ? "code-view" : "design-view"
      }`}
    >
      <div className="aboutme-content">
        <section className="aboutbody">
          <div className="abouttext">
            <p className="aboutsubtitlecase">About me</p>
            <Spacing className="spacing-sm" />
            <p className="aboutpcase">
              Hello, I'm Oscar.
              <br />
              <br />
              I'm passionate about bridging the gap between designers and
              developers.
              <br />
              <br />
              As a product designer, I bring a holistic view to the entire
              experience, from the marketing strategies that drive traffic to my
              designs, to the data analytics that reveal user behavior. I
              believe that having a broad perspective is essential for planning
              and executing effective design solutions.
              <br />
              <br />
              My expertise covers the entire design process, combining design
              skills with coding, data analysis, and a deep understanding of
              metrics and KPIs. Strategic thinking is key to my role, and I work
              closely with product owners and data analysts to challenge and
              refine our initiatives.
              <br />
              <br />
              This comprehensive approach allows me to create efficient design
              processes and deliver solutions that are not only visually
              appealing but also aligned with user needs and business goals.
            </p>
            <Spacing className="spacing-sm" />
            <p className="abouthcase">When I disconnect</p>
            <Spacing className="spacing-sm" />
            <p className="aboutpcase">
              When I'm not immersed in learning, designing, or brainstorming the
              next big idea, you'll find me enjoying Marvel movies, playing the
              guitar, or having a drink with friends at a bar in Barcelona.
            </p>
          </div>
          <div className="gridimg">
            <div className="containerimg">
              <img src={pfp} alt="Profile" className="imgabout" />
              <img src={code} alt="Code" className="imgabout" />
            </div>
            <div className="containerimg">
              <img src={bcn} alt="Barcelona" className="imgabout" />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AboutmePage;
