import React from "react";
import "./Footer.css";
import { FilePdf, EnvelopeSimple, LinkedinLogo } from "@phosphor-icons/react";

function Footer({ isLogicVisible }) {
  const handleDownload = () => {
    window.location.href =
      "https://drive.google.com/uc?export=download&id=1QjsDikeppGoEKx4FVERZHEiFaaCc_9zC"; // Replace with the actual URL to your CV
  };
  const handleEmail = () => {
    window.location.href =
    "mailto:orabadanux@gmail.com";
  };
  const handleLinkedIn = () => {
    window.open("https://www.linkedin.com/in/oscar-rabadan-fuertes/", "_blank"); // Replace with the actual URL to your CV
  };

  return (
    <div className={`footer-section ${isLogicVisible ? 'code-view' : 'design-view'}`}>
      <div className="footer-content">
        <h1 className="footer-title">Let's get in touch</h1>
        <div className="footer-links">
          <button className="footer-button-black" onClick={handleDownload}>
            Resume{" "}
            <FilePdf size={20} weight="fill" className="footer-icon" />
          </button>
          <button className="footer-button-black" onClick={handleEmail}>
            Email{" "}
            <EnvelopeSimple size={20} weight="regular" className="footer-icon" />
          </button>
          <button className="footer-button-black" onClick={handleLinkedIn}>
            Linkedin{" "}
            <LinkedinLogo size={20} weight="fill" className="footer-icon" />
          </button>
        </div>
        
      </div>
      <p className="footer-caption">
          © Crafted in Barcelona by Oscar Rabadán
        </p>
    </div>
  );
}

export default Footer;
