import React from 'react';
import PropTypes from 'prop-types';

const Spacing = ({ className }) => {
  return <div className={`spacing ${className}`}></div>;
};

Spacing.propTypes = {
  className: PropTypes.string,
};

Spacing.defaultProps = {
  className: '',
};

export default Spacing;