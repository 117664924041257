import React from 'react';
import { FilePdf } from "@phosphor-icons/react";
import pfp from '../assets/profilepic.png'; // Ensure the path is correct based on your project structure
import "../App.css";
function NavBar({ isLogicVisible }) {
    const handleDownload = () => {
      window.location.href =
        "https://drive.google.com/uc?export=download&id=1QjsDikeppGoEKx4FVERZHEiFaaCc_9zC"; // Replace with the actual URL to your CV
    };
    return (
      <div className={`navbar ${isLogicVisible ? 'code-view' : 'design-view'}`}>
        <div className="navpfp">
          <img src={pfp} alt="Oscar Rabadán" className="" />
          <span className={`navtext ${isLogicVisible ? 'code-view' : 'design-view'}`}>Oscar Rabadán</span>
        </div>
        <span className="navcv">
        <button className="nav-button" onClick={handleDownload}>
        <span>Resume </span>
            <FilePdf size={20} weight="fill" className="footer-icon" />
          </button>
        </span>
      </div>
    );
  }

export default NavBar;