import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../CaseStyles.css";
import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar";
import { ArrowLeft } from "@phosphor-icons/react";
import Spacing from "../../Spacing";
import rpapresen from "../../../assets/RPA/rpacardpresentation.png";
import problem1 from "../../../assets/RPA/problem1.png";
import problem2 from "../../../assets/RPA/problem2.png";
import problem3 from "../../../assets/RPA/problem3.png";
import confirm from "../../../assets/RPA/confirm.png";
import designui from "../../../assets/RPA/designui.png";
import introrpa from "../../../assets/RPA/introrpa.png";
import manual from "../../../assets/RPA/manual.png";
import preparking from "../../../assets/RPA/preparking.png";
import realtime from "../../../assets/RPA/realtime.png";

function PromoMechanismPage({ isLogicVisible }) {
  console.log("Dark Mode Active:", isLogicVisible);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className={`case-section ${isLogicVisible ? "code-view" : "design-view"}`}
    >
      <Navbar isLogicVisible={isLogicVisible} />
      <Spacing size="32px" />
      <div className="breadcrumb">
        <ArrowLeft size={20} weight="bold" />
        <Link to="/">
          <p className="casehomelink">Back</p>
        </Link>
      </div>
      <div className="case-content">
        <p className="titlecase">
          Designed a mobile feature to let users park their car automatically
        </p>
        <Spacing size="40px" />
        <div className="divDet">
          <div className="containerDet">
            <p className="detLabel">Role</p>
            <p className="detText">Product Designer</p>
          </div>
          <div className="containerDet">
            <p className="detLabel">Company</p>
            <p className="detText">
              Car manufacturer
            </p>
          </div>
          <div className="containerDet">
            <p className="detLabel">Project timeline</p>
            <p className="detText">Jan 2023 - Jul 2023</p>
          </div>
        </div>
        <Spacing size="40px" />
        <div className="imgcase">
          <img src={rpapresen} alt="placeholder" />
        </div>
        <Spacing size="40px" />
        <p className="pcase">
          Parking can be a stressful and challenging task for many drivers. To
          enhance the user experience and increase convenience, our company
          introduced a new feature: Automatic Parking.
          <br />
          <br />
          This feature, initially available through the car's infotainment
          system, allows users to park their cars remotely using a mobile app
          designed for vehicle management, which includes functionalities like
          climate control, lock/unlock and battery charging.
        </p>
        <Spacing size="40px" />
        <div className="infonda">
          <p className="pinfo">
            To comply with my non-disclosure agreement, I have omitted and
            obfuscated confidential information in this case study. All
            information in this case study is my own and does not necessarily
            reflect the views of the bank.
          </p>
        </div>
        <Spacing size="32px" />
        <div className="imgcase">
          <img src={introrpa} alt="placeholder" />
        </div>
        <Spacing size="32px" />
        <p className="subtitlecase">The Challenge</p>
        <Spacing size="16px" />
        <p className="pcase">
          The goal was to translate the existing Automatic Parking feature from
          the car's infotainment system to the mobile app, ensuring a seamless
          and secure experience. This involved not only replicating the
          functionality but also addressing technical constraints and potential
          errors in the mobile environment.
          <br />
          <br />
          The challenge was to ensure the feature was intuitive, safe, and
          reliable while navigating both automotive and mobile UX constraints.
          <br />
          <br />
          Objectives:
          <br />
          <ul>
            <li>
              Create an intuitive and easy-to-use parking process on the mobile
              app.
            </li>
            <li>Ensure user safety and vehicle security.</li>
            <li>Balance technical feasibility with user needs.</li>
            <li>
              Conduct thorough user research to understand needs and pain
              points.
            </li>
            <li>Continuously improve through user feedback and testing.</li>
          </ul>
        </p>
        <Spacing size="32px" />
        <p className="subtitlecase">My Role</p>
        <Spacing size="16px" />
        <p className="pcase">
          As the product designer, I worked from January 2023 to July 2023
          alongside a multidisciplinary team of frontend and backend developers,
          a product manager, and QA specialists. Additional roles involved
          included business analysts, a function owner, and a translation
          department.
        </p>
        <Spacing size="32px" />
        <p className="subtitlecase">Kickoff: Gathering Insights</p>
        <Spacing size="16px" />
        <p className="pcase">
          I began with comprehensive research to gather insights:
          <br />
          <br />
          <ul>
            <li>
              Quantitative Data: Analysis of existing user behavior in the
              infotainment system and mobile app.
            </li>
            <li>
              Qualitative Insights: Feedback from customer service and user
              interviews.
            </li>
            <li>
              Technical Insights: Discussions with the function owner about
              vehicle capabilities and mobile app constraints.
            </li>
            <li>
              Strategic Input: Business analysts provided the strategic focus
              and goals.
            </li>
            <li>
              Competitive Analysis: Benchmarks from other automotive apps.
            </li>
          </ul>
        </p>
        <Spacing size="32px" />
        <p className="subtitlecase">Early Insights from Data</p>
        <Spacing size="16px" />
        <p className="pcase">
          <ul>
            <li>
              Users were concerned about the safety and security of automatic
              parking.
            </li>
            <li>
              There was a preference for clear, step-by-step guidance during the
              parking process.
            </li>
            <li>
              Users desired immediate feedback and confirmation at each step.
            </li>
            <li>
              Integration with existing app features should be seamless and
              intuitive.
            </li>
            <li>
              Mobile app users expected the same reliability as the infotainment
              system.
            </li>
          </ul>
        </p>
        <Spacing size="32px" />
        <p className="subtitlecase">Discovery: Ensuring Safety and Usability</p>
        <Spacing size="16px" />
        <p className="pcase">
          Understanding user needs was paramount. Users required transparency,
          control, and assurance that the system was safe and reliable. Our
          focus was on creating a user-friendly interface that instilled
          confidence.
        </p>
        <Spacing size="32px" />
        <p className="subtitlecase">Introducing Automatic Parking</p>
        <Spacing size="16px" />
        <p className="pcase">
          We designed the Automatic Parking feature for the mobile app with the
          following components:
        </p>
        <Spacing size="32px" />
        <p className="hcase">Pre-Parking Checks</p>
        <Spacing size="16px" />
        <p className="pcase">
          The app performs safety and technical checks before initiating
          parking.
        </p>
        <Spacing size="32px" />
        <div className="imgcase">
          <img src={preparking} alt="placeholder" />
        </div>
        <Spacing size="32px" />
        <p className="hcase">Real-Time Guidance</p>
        <Spacing size="16px" />
        <p className="pcase">Step-by-step instructions with visual feedback.</p>
        <Spacing size="32px" />
        <div className="imgcase">
          <img src={realtime} alt="placeholder" />
        </div>
        <Spacing size="32px" />
        <p className="hcase">Manual Override</p>
        <Spacing size="16px" />
        <p className="pcase">
          Users can manually override the automatic parking at any time.
        </p>
        <Spacing size="32px" />
        <div className="imgcase">
          <img src={manual} alt="placeholder" />
        </div>
        <Spacing size="32px" />
        <p className="hcase">Confirmation and Feedback</p>
        <Spacing size="16px" />
        <p className="pcase">
          Clear notifications and confirmations at each stage of the parking
          process.
        </p>
        <Spacing size="32px" />
        <div className="imgcase">
          <img src={confirm} alt="placeholder" />
        </div>
        <Spacing size="32px" />
        <p className="subtitlecase">How We Got There</p>
        <Spacing size="16px" />
        <p className="pcase">
          I had three key points obtained from the generation and collection of
          data to define the experience:
          <br />
          <br />
          <ul>
            <li>How to ensure safety and security?</li>
            <li>
              What is the best way to guide users through the parking process?
            </li>
            <li>How to design for a diverse user base?</li>
          </ul>
        </p>
        <Spacing size="32px" />
        <p className="subtitlecase">
          The Problem: Technical Constraints in Automotive and Mobile UX
        </p>
        <Spacing size="16px" />
        <p className="pcase">
          Designing for a vehicle involves unique challenges that differ from
          traditional digital products.
        </p>
        <Spacing size="32px" />
        <p className="hcase">Sensor Limitations and Integration</p>
        <Spacing size="16px" />
        <p className="pcase">
          My initial proposals relied heavily on the visualization and the use
          of high-precision sensors to detect obstacles and navigate tight
          parking spaces. However, the function owner pointed out limitations in
          the existing sensor technology, which could affect accuracy and
          reliability.
          <br />
          <br />
          I worked closely with the engineering and development teams to
          understand the capabilities and limitations of the current sensor
          suite.
          <br />
          <br />
          After sessions with the engineers and developers I decided to use the
          notification banners, since the services to visually display the
          sensors were not developed and if we chose to develop them we would
          not meet the deadline.
          <br />
          <br />
          Finally, after sessions with strategy and business analysts I was able
          to convince them to extend the deadline to come out with the sensor
          visualization.
        </p>
        <Spacing size="32px" />
        <div className="imgcase">
          <img src={problem1} alt="placeholder" />
        </div>
        <Spacing size="32px" />
        <p className="hcase">Real-Time Data Processing</p>
        <Spacing size="16px" />
        <p className="pcase">
          We had a lot of information and errors that we had to show to ensure
          the safety of the user and the vehicle.
          <br />
          <br />
          The experience defined required real-time data processing to provide
          immediate feedback and control during the parking process. Initial
          tests showed delays that could impact user trust and safety.
          <br />
          <br />I had sessions with backend developers to explain the problem
          and try to solve it from a design or development perspective. Finally,
          the developers were able to optimize the data transmission, reducing
          latency and improving the real-time user experience.
        </p>
        <Spacing size="32px" />
        <div className="imgcase">
          <img src={problem2} alt="placeholder" />
        </div>
        <Spacing size="32px" />
        <p className="hcase">User Interface for Complex Systems</p>
        <Spacing size="16px" />
        <p className="pcase">
          Designing a user interface that simplifies a complex process like
          automatic parking, which involves multiple sensors and real-time data,
          was challenging. The interface needed to convey critical information
          without overwhelming the user.
          <br />
          <br />I focused on minimalist design principles, using clear icons and
          step-by-step instructions. I also implemented a contextual help system
          that provided additional information only when needed, ensuring the
          interface remained clean and intuitive.
        </p>
        <Spacing size="32px" />
        <div className="imgcase">
          <img src={problem3} alt="placeholder" />
        </div>
        <Spacing size="32px" />
        <p className="subtitlecase">Testing challenges</p>
        <Spacing size="16px" />
        <p className="pcase">
          Testing was critical. We conducted:
          <br />
          <br />
          <ul>
            <li>
              User Testing: Unmoderated tests to gather initial user feedback.
            </li>
            <li>
              Field Tests: Real-world scenarios to ensure reliability and
              safety.
            </li>
            <li>A/B Testing: Essential for refining the user experience.</li>
          </ul>
        </p>
        <Spacing size="32px" />
        <p className="subtitlecase">Designing the User Interface</p>
        <Spacing size="16px" />
        <p className="pcase">
          User feedback showed a need for simplicity and clarity. We focused on:
          <br />
          <br />
          <ul>
            <li>Clear Visuals: Easy-to-understand icons and instructions.</li>
            <li>
              Feedback Mechanisms: Real-time feedback on the parking status.
            </li>
            <li>
              Accessibility: Ensuring the app was usable by all drivers,
              regardless of tech-savviness.
            </li>
          </ul>
        </p>
        <Spacing size="32px" />
        <div className="imgcase">
          <img src={designui} alt="placeholder" />
        </div>
        <Spacing size="32px" />
        <p className="subtitlecase">Positive Impact and Future Work</p>
        <Spacing size="16px" />
        <p className="pcase">
          We launched this feature for a 5% of the users in order to test it in
          production. The new feature showed promising results:
          <br />
          <br />
          <ul>
            <li>
              User Adoption: High initial adoption rate, with 70% of users
              trying the feature within the first month.
            </li>
            <li>
              User Satisfaction: 85% of users reported a positive experience
              with the feature.
            </li>
            <li>
              Safety Incidents: Zero safety incidents reported during the
              testing phase.
            </li>
            <li>
              Customer Feedback: Positive feedback on the intuitiveness and
              reliability of the feature.
            </li>
            <li>
              NPS {"(Net Promoter Score)"}: Achieved an NPS of 72, indicating
              high user satisfaction and likelihood to recommend.
            </li>
          </ul>
          <br />
          The Automatic Parking feature is set to officially launch in October
          2024, with continuous improvements based on user feedback and
          technological advancements.
        </p>
        <Spacing size="32px" />
      </div>
      <Footer isLogicVisible={isLogicVisible} />
    </div>
  );
}

export default PromoMechanismPage;
