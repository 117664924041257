
import presentation from "../../assets/installments/cardpresentation.png";
import rpa from "../../assets/RPA/rpacardpresentation.png";
import greenbcn from "../../assets/greenbcn/gbcncardpresentation.png";
import coupons from "../../assets/coupons/couponscardpresentation.png";

export const cardsData = {
    productDesign: [
      {
        title:
          "Achieved 100% increase in revenues due to installment payment functionality",
        background: presentation, // Update path accordingly
        readTitle: "Reading time",
        readText:"8 min",
        redirectPath: "/installments"
      },
      {
        title:
        "Designed a mobile feature to let users park their car automatically",
        background: rpa, // Update path accordingly
        readTitle: "Reading time",
        readText:"6 min",
        redirectPath: "/automaticparking"
      },
      {
        title:
        "Defined a promotional mechanism system for sellers of a marketplace",
        background: coupons, // Update path accordingly
        readTitle: "Reading time",
        readText:"7 min",
        redirectPath: "/coupons"
      },
      {
        title:
          "Making Barcelona cleaner through an integrated recycling and reporting app",
        background: greenbcn, // Update path accordingly
        readTitle: "Reading time",
        readText:"5 min",
        redirectPath: "/greenbcn"
      },
    ],
  };