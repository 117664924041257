import React from "react";

function BodyText() {


  return (
    <pre>
      <span className="code-grey">{"</"}</span>
      <span className="code-blue">div</span>
      <span className="code-grey">{">"}</span>
      <span className="code-purple">{")"}</span>
      <span className="code-grey">;</span>
      <br />
      <span className="code-yellow">{"}"}</span>
      <br />
      <span className="code-purple">export default</span>
      <span className="code-yellow"> HeroSection</span>
      <span className="code-grey">;</span>
    </pre>
  );
}

export default BodyText;
