import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./components/HomePage/HomePage";
import RPAPage from "./components/Casestudies/RPA/RPAPage";
import GreenBCNPage from "./components/Casestudies/GreenBarcelona/GreenBCNPage";
import InstallmentsPage from "./components/Casestudies/Installments/InstallmentsPage";
import PromoMechanismPage from "./components/Casestudies/PromoMechanism/PromoMechanismPage";
import NavBar from "./components/Navbar";

import "./App.css";

function App() {
  const [isLogicVisible, setIsLogicVisible] = useState(false);

  const toggleSwitch = () => {
    setIsLogicVisible(prev => !prev);
  };

  return (
    <Router>
      <div className="nav-container">
        <NavBar isLogicVisible={isLogicVisible} />
        <Routes>
          <Route path="/" element={<HomePage isLogicVisible={isLogicVisible} toggleSwitch={toggleSwitch} />} />
          <Route path="/automaticparking" element={<RPAPage isLogicVisible={isLogicVisible} />} />
          <Route path="/greenbcn" element={<GreenBCNPage isLogicVisible={isLogicVisible} />} />
          <Route path="/installments" element={<InstallmentsPage isLogicVisible={isLogicVisible} />} />
          <Route path="/promomechanism" element={<PromoMechanismPage isLogicVisible={isLogicVisible} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
