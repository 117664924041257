import React from "react";

function HeaderText() {
  return (
    <pre>
      <span className="code-blue">function</span>
      <span className="code-yellow"> HeroSection() {"{"}</span>
      <br />
      <span className="code-purple">return {"("}</span>
      <br />
      <span className="code-grey">{"<"}</span>
      <span className="code-blue">div</span>
      <span className="code-lightblue"> className</span>
      <span className="code-grey">=</span>
      <span className="code-orange">"hero-content"</span>
      <span className="code-grey">{">"}</span>
      <br />
      <span className="code-grey">{"<"}</span>
      <span className="code-blue">div</span>
      <span className="code-lightblue"> className</span>
      <span className="code-grey">=</span>
      <span className="code-orange">"hero-title"</span>
      <span className="code-grey">{">"}</span>
      <span className="code-white">
        I'm Oscar, a Product Designer from Barcelona bridging the gap between{"<"}
      </span>
      <span className="code-blue">h1</span>
      <span className="code-grey">{">"}</span>
    </pre>
  );
}

export default HeaderText;
