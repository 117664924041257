import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../CaseStyles.css";
import Footer from "../../Footer/Footer";
import { ArrowLeft } from "@phosphor-icons/react";
import Spacing from "../../Spacing";
import gbcnpresen from "../../../assets/greenbcn/gbnpresen.png";
import reclocator from "../../../assets/greenbcn/reclocator.png";
import introgbn from "../../../assets/greenbcn/introgbn.png";
import comm from "../../../assets/greenbcn/community.png";
import increport from "../../../assets/greenbcn/increport.png";
import problem1 from "../../../assets/greenbcn/problem1.png";
import problem2 from "../../../assets/greenbcn/problem2.png";
import problem3 from "../../../assets/greenbcn/problem3.png";
import Card from "../../CaseSection/Card";
import { cardsData } from "../../CaseSection/cardsData";

function RPAPage({ isLogicVisible }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const redirectCard = cardsData.productDesign.find(
    (card) => card.redirectPath === "/installments"
  );

  return (
    <div
      className={`case-section ${isLogicVisible ? "code-view" : "design-view"}`}
    >
      <div className="breadcrumb">
        <Link to="/">
          <ArrowLeft size={24} weight="bold" />

          <p className="casehomelink">Back</p>
        </Link>
      </div>
      <div className="case-content">
        <p className="titlecase">
          Making Barcelona cleaner through an integrated recycling and reporting
          app
        </p>
        <Spacing className="spacing-lg" />
        <div className="divDet">
          <div className="containerDet">
            <p className="detLabel">Role</p>
            <p className="detText">UX/UI Designer</p>
          </div>
          <div className="containerDet">
            <p className="detLabel">Company</p>
            <p className="detText">Barcelona City Council</p>
          </div>
          <div className="containerDet">
            <p className="detLabel">Project timeline</p>
            <p className="detText">Nov 2021 - Feb 2022</p>
          </div>
        </div>
        <Spacing className="spacing-xl" />
        <div className="imgcase">
          <img
            className="imgsm"
            src={gbcnpresen}
            alt="placeholder"
            loading="lazy"
          />
        </div>
        <Spacing className="spacing-xl" />
        <p className="pcase">
          In an effort to maintain the cleanliness of Barcelona and engage its
          citizens in keeping the city beautiful, the Barcelona City Council
          sought to develop a comprehensive app.
          <br />
          <br />
          This app would help residents find recycling locations, report
          cleaning incidents, and participate in district plenary sessions to
          share their thoughts, problems and suggestions.
          <br />
          <br />
          Previously, these features were available across different websites,
          leading to a fragmented user experience. Our goal was to unify these
          features into a single, intuitive, and user-friendly app to empower
          citizens in maintaining the city's cleanliness.
        </p>
        <Spacing className="spacing-lg" />
        <div className="infonda">
          <p className="pinfo">
            To comply with my non-disclosure agreement, I have omitted and
            obfuscated confidential information in this case study. All
            information in this case study is my own and does not necessarily
            reflect the views of the bank.
          </p>
        </div>
        <Spacing className="spacing-xl" />
        <div className="imgcase">
          <img
            className="imgsm"
            src={introgbn}
            alt="placeholder"
            loading="lazy"
          />
        </div>
        <Spacing className="spacing-xl" />
        <p className="subtitlecase">The Challenge</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          Our objective was to design and implement an app from scratch that
          would encourage and facilitate community involvement in maintaining
          the city’s cleanliness. This required a deep understanding of user
          needs, strict adherence to UX and accessibility principles, and a
          design that was both functional and engaging.
          <br />
          <br />
          Our mission was clear:
          <br />
          <ul>
            <li>Empower users to keep the city clean.</li>
            <li>Create an intuitive and user-friendly app.</li>
            <li>Ensure the app meets all accessibility standards.</li>
            <li>
              Unify existing features to provide a seamless user experience.
            </li>
            <li>
              Continuously iterate and test the app for optimal performance.
            </li>
          </ul>
        </p>
        <Spacing className="spacing-md" />
        <p className="subtitlecase">My Role</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          As the UX/UI designer, I worked from November 2021 to February 2022
          with a team of UX/UI designers. We collaborated with developers,
          product managers and accessibility experts to define and refine the
          experience.
          <br />
          <br />
          Additionally, we collaborated with city council officials and
          community representatives to test the app with them and other
          citizens, ensuring it met the needs of Barcelona’s residents.
        </p>
        <Spacing className="spacing-md" />
        <p className="subtitlecase">Kickoff: Gathering Insights</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          We began by gathering extensive information:
          <ul>
            <li>
              User needs and preferences through surveys and focus groups.
            </li>
            <li>
              Technical requirements and constraints from the development team.
            </li>
            <li>Accessibility standards from government regulations.</li>
            <li>
              Insights from city council officials on the primary issues and
              desired features.
            </li>
          </ul>
        </p>
        <Spacing className="spacing-md" />
        <p className="subtitlecase">Early Insights from Data</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          <ul>
            <li>Residents needed a simple way to find recycling locations.</li>
            <li>
              There was a strong desire to report cleaning issues easily and
              efficiently.
            </li>
            <li>
              Citizens wanted a platform to voice their ideas and participate in
              community discussions.
            </li>
          </ul>
        </p>
        <Spacing className="spacing-md" />
        <p className="subtitlecase">Discovery: Ease of use is the key point</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          Users emphasized the need for a straightforward, accessible app. They
          wanted clear instructions, easy navigation, and features that made
          their participation in city cleanliness effortless. Our challenge was
          to design an app that was not only effective but also inclusive.
        </p>
        <Spacing className="spacing-md" />
        <p className="subtitlecase">Defining the Problem: Service blueprint</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          The problem wasn’t just about developing an app; it was about creating
          a tool that empowered citizens and met stringent accessibility
          standards.
          <br />
          <br />
          I worked on a service blueprint to get a clear picture of the
          experience, roles involved, and service design for the plenary
          sessions.
          <br />
          <br />
          This blueprint outlined the different steps of the experience (pre,
          during, and post) for each feature, identifying the actors involved,
          the frontstage (what the user sees), the backstage (what the user
          doesn’t see), and the opportunities, gains, and pain points in each
          step.
        </p>
        <Spacing className="spacing-md" />
        <p className="subtitlecase">Introducing the Green Barcelona App</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          Our app was designed with the service blueprint in mind and included
          the following features:
        </p>
        <Spacing className="spacing-md" />
        <p className="hcase">Recycling Locator</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          Users can enter the type of waste and their address ahd the app will
          show the nearest recycling locations with directions.
        </p>
        <Spacing className="spacing-xl" />
        <div className="imgcase">
          <img
            className="imgmd"
            src={reclocator}
            alt="placeholder"
            loading="lazy"
          />
        </div>
        <Spacing className="spacing-xl" />
        <p className="hcase">Incident Reporting</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          Users can report cleaning issues like graffiti or street waste. A
          simple form allows users to upload photos and provide descriptions.
        </p>
        <Spacing className="spacing-xl" />
        <div className="imgcase">
          <img
            className="imgmd"
            src={increport}
            alt="placeholder"
            loading="lazy"
          />
        </div>
        <Spacing className="spacing-xl" />
        <p className="hcase">Community Engagement</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          Users can sign up for district plenary sessions through a form. Users
          can submit questions and suggestions for the sessions.
        </p>
        <Spacing className="spacing-xl" />
        <div className="imgcase">
          <img className="imglg" src={comm} alt="placeholder" loading="lazy" />
        </div>
        <Spacing className="spacing-xl" />
        <p className="subtitlecase">How We Got There</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          Three primary questions guided our design strategy:
          <ul>
            <li>How to empower users to participate in city cleanliness?</li>
            <li>
              How to encourage collaboration in plenary sessions with
              suggestions?
            </li>
            <li>
              How to foster a mentality of recycling and using the search
              feature to find the nearest waste disposal?
            </li>
          </ul>
          <br />
          <br />
          Understanding the complexity and importance of accessibility, we
          prioritized simplicity, transparency, and inclusivity. Our
          collaboration with accessibility experts ensured compliance with AA
          criteria standards, while continuous user testing helped refine the
          user experience.
        </p>
        <Spacing className="spacing-md" />
        <p className="subtitlecase">
          Addressing problems during design process
        </p>
        <Spacing className="spacing-md" />
        <p className="hcase">Confusing Recycling Locator Screen</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          The first version of the recycling location locator was a map with the
          different points to recycle different wastes, since the backend
          services were not ready to create a search engine at the defined
          deadline.
          <br />
          <br />
          We had several sessions to try to justify creating the search engine,
          as it was a more usable option. Stakeholders refused because it would
          mean delaying the deadline.
          <br />
          <br />
          What I did was to take this wireframe version to the first user tests
          and the results were bad.
          <br />
          <br />
          All the users told us that this option was difficult to use and that
          they wanted to be able to write which waste they wanted to recycle and
          in which address they live to find the nearest places.
          <br />
          <br />
          With these results, they finally decided to postpone the deadline and
          work on the search engine.
        </p>
        <Spacing className="spacing-xl" />
        <div className="imgcase">
          <img
            className="imglg"
            src={problem1}
            alt="placeholder"
            loading="lazy"
          />
        </div>
        <Spacing className="spacing-xl" />
        <p className="hcase">Complicated Incident Reporting Form</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          The incident report form was very long. I had sessions with
          stakeholders to see which fields could be dispensable. They told me
          that they wanted to have all the fields, but they were open to
          eliminating fields that users would find frustrating to fill out in
          the test.
          <br />
          <br />
          All the users told us:
          <br />
          <ul>
            <li>The form, as we assumed, was too long.</li>
            <li>
              They did not have a clear perception of the steps to follow.
            </li>
            <li>
              They did not know what was going to happen after submitting the
              report, as no information was provided about the steps that the
              municipality was going to take to solve the issue.
            </li>
          </ul>
          <br />
          <br />
          With this information, we iterated the form. Stakeholders welcomed the
          new version.
        </p>
        <Spacing className="spacing-xl" />
        <div className="imgcase">
          <img
            className="imglg"
            src={problem2}
            alt="placeholder"
            loading="lazy"
          />
        </div>
        <Spacing className="spacing-xl" />
        <p className="hcase">Low Engagement in Plenary Sessions</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          One of the key points of the plenary sessions was that citizens did
          not attend. About 5% of the district's citizens attended, and they
          were almost always the same people, as they tended to be neighborhood
          associations.
          <br />
          <br />
          The experience of registering to participate in the plenary sessions
          was tedious. It was done via web with a long form.
          <br />
          <br />
          In this form, the user had to add a question or suggestion to comment
          in the plenary, and the city council would call you a few days before
          the plenary to let you know if that question was going to be made or
          not.
          <br />
          <br />
          If the question was not asked, the citizen could not go to the
          plenary.
          <br />
          <br />
          I proposed an experience with a simpler form and had sessions with
          stakeholders to try to make the question validation process more
          efficient.
          <br />
          <br />
          With the new app we were going to have a centralized platform, so I
          proposed that the moderation of the questions could be done by a
          different user role that could use the app to choose the questions
          suitable for the plenary.
          <br />
          <br />
          I validated this solution with the backend developers and worked on a
          flow so that city council employees would have a section in the app to
          validate questions.
          <br />
          <br />
          In addition, for citizens who were refused the question, they would
          have the option to go as listeners, as long as there were available
          seats, since the priority would be for citizens with a question.
        </p>
        <Spacing className="spacing-xl" />
        <div className="imgcase">
          <img
            className="imglg"
            src={problem3}
            alt="placeholder"
            loading="lazy"
          />
        </div>
        <Spacing className="spacing-xl" />
        <p className="subtitlecase">Positive Impact and Future Work</p>
        <Spacing className="spacing-xs" />
        <p className="pcase">
          The introduction of the Green Barcelona app showed promising results
          during the first three months:
          <br />
          <br />
          <ul>
            <li>
              Increased user engagement, with 30% of residents using the app
              regularly. For perspective, the most used functionality in the
              past was the recycling locator, which was used by 7% of the
              residents regularly.
            </li>
            <li>
              High satisfaction rates, with 90% of users finding the app "very
              helpful."
            </li>
            <li>
              The perception of the city's cleanliness went from 42% to 68% in 2
              months.
            </li>
            <li>
              Enhanced community participation in district plenary sessions.
              More than 60% of the plenary sessions were full in the last 3
              months.
            </li>
            <li>
              Net Promoter Score (NPS): 87 for the new app, compared to 47 for
              the recycling locator.
            </li>
            <li>
              Incident Reporting Efficiency: 112% increase in reported incidents
              due to easier reporting mechanisms.
            </li>
            <li>
              Recycling Locator Use: 54% increase in users finding and using
              recycling locations effectively.
            </li>
          </ul>
        </p>
        <Spacing className="spacing-xl" />
        <div className="casedivider"></div>
        <Spacing className="spacing-xl" />
        <div className="nextcasesection">
          <p className="nextcasetitle">Next case study</p>

          <Card {...redirectCard} />
        </div>
      </div>
      <Footer isLogicVisible={isLogicVisible} />
    </div>
  );
}

export default RPAPage;
